// VideoPage.js

import React from 'react';
import './global.css';

function VideoPage() {
  return (
    <div className="page-container">
      <h1 className='title-text'>Video</h1>
      <p>Video coming soon!!</p>
    </div>
  );
}

export default VideoPage;
